<template lang="">
   <div class="bg-primary font-inter">
      
      <Menu />

      <div class="relative z-10 flex items-center justify-center h-full p-8">
         <div class="text-primary h-[60vh] relative flex items-center justify-center p-8">
            <div class="max-w-screen mx-auto animate-ease-in-out">
               <div class="max-w-lg mx-auto text-center">
                  <div class="flex items-center justify-center mb-4">
                     <img class="w-[120px]" src="@/assets/tools/brandBuilder.png" alt="icon" />
                  </div>
                  <h2 class="heading">
                  Brand Builder
                  </h2>
                  <span class="font-semibold text-lg text-white mb-2 block">
                     LogoYa’s Brand Builder crafts a custom logo and design assets tailored to your business – all in seconds. Just enter a few details, and watch your brand come to life.
                  </span> 
                  <div class="mt-auto text-center">
                     <router-link to="/questionnaire">
                        <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5 xl:mt-0">Start Working →</button>  
                     </router-link>
                  </div>  
               </div>                
            </div>
         </div>
      </div>
  
      <!-- TOOLS SECTION -->
      <section class="relative bg-cover flex justify-center pt-20 lg:pt-[120px] pb-12 lg:pb-[90px]">
         <!-- Blobs -->
         <img src="@/assets/blob.gif" alt="blob" class="absolute top-0 left-5 w-28 h-28 xl:w-36 xl:h-36"/>
         <img src="@/assets/blob.gif" alt="blob" class="absolute bottom-0 left-1/2 w-20 h-20 xl:w-24 xl:h-24"/>
         <img src="@/assets/blob.gif" alt="blob" class="absolute top-1/2 right-0 xl:right-20 w-24 h-24 xl:w-28 xl:h-28"/>
         <div class="container">
            <div class="flex flex-wrap mx-4">
               <div class="w-full px-4">
                  <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                     <h2 class="heading">
                     Brand Builder Benefits
                     </h2>
                     <span class="font-semibold text-lg text-white mb-2 block">
                     Your brand in just few clicks.
                     </span>                
                  </div>
               </div>
            </div>
            <div class="flex flex-wrap gap-y-12">
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M18.6746 0.26779C10.0637 -1.33065 1.86522 4.39279 0.266779 13.0037C-0.506658 17.1803 0.421467 21.3568 2.79334 24.8631C5.21678 28.3693 8.82615 30.6897 12.9512 31.4631C13.9308 31.6178 14.859 31.7209 15.7871 31.7209C23.2637 31.7209 29.9668 26.3584 31.359 18.6756C32.9574 10.0647 27.234 1.81466 18.6746 0.26779ZM29.6574 18.3662C29.5543 18.8819 29.4512 19.449 29.2965 19.9131L16.7668 15.2209V1.81466C17.2824 1.86623 17.8496 1.91779 18.3652 2.02091C25.9449 3.4131 30.998 10.735 29.6574 18.3662ZM14.9105 1.81466V14.9115H1.86522C1.91678 14.3959 1.96834 13.8287 2.07147 13.3131C3.20584 6.86779 8.67147 2.22716 14.9105 1.81466ZM13.3121 29.6584C9.65115 28.9881 6.45428 26.9256 4.28865 23.8318C2.79334 21.7178 1.96834 19.2428 1.81365 16.7678H15.6324L28.5746 21.6147C26.064 27.3381 19.7574 30.8443 13.3121 29.6584Z"
                        fill="white"/>
                        </svg>
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                        Customized for Your Business
                     </h4>
                     <p class="text-center text-white">
                        We analyze your business details to create unique logos and designs that fit your brand’s personality.
                     </p>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M18.6746 0.26779C10.0637 -1.33065 1.86522 4.39279 0.266779 13.0037C-0.506658 17.1803 0.421467 21.3568 2.79334 24.8631C5.21678 28.3693 8.82615 30.6897 12.9512 31.4631C13.9308 31.6178 14.859 31.7209 15.7871 31.7209C23.2637 31.7209 29.9668 26.3584 31.359 18.6756C32.9574 10.0647 27.234 1.81466 18.6746 0.26779ZM29.6574 18.3662C29.5543 18.8819 29.4512 19.449 29.2965 19.9131L16.7668 15.2209V1.81466C17.2824 1.86623 17.8496 1.91779 18.3652 2.02091C25.9449 3.4131 30.998 10.735 29.6574 18.3662ZM14.9105 1.81466V14.9115H1.86522C1.91678 14.3959 1.96834 13.8287 2.07147 13.3131C3.20584 6.86779 8.67147 2.22716 14.9105 1.81466ZM13.3121 29.6584C9.65115 28.9881 6.45428 26.9256 4.28865 23.8318C2.79334 21.7178 1.96834 19.2428 1.81365 16.7678H15.6324L28.5746 21.6147C26.064 27.3381 19.7574 30.8443 13.3121 29.6584Z"
                        fill="white"/>
                        </svg>
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                        Professional Results, Fast and Effortless
                     </h4>
                     <p class="text-center text-white">
                        Generate quality logos and design assets in seconds, with no design skills needed.
                     </p>
                  </div>
               </div>
               <div class="w-full md:w-1/3 lg:w-1/3 px-4">
                  <div class="glass p-[20px] md:px-7 xl:px-10 rounded-[20px] shadow-md mb-8 h-full flex flex-col flex-grow">
                     <div class="flex items-center justify-center mb-4">
                        <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M18.6746 0.26779C10.0637 -1.33065 1.86522 4.39279 0.266779 13.0037C-0.506658 17.1803 0.421467 21.3568 2.79334 24.8631C5.21678 28.3693 8.82615 30.6897 12.9512 31.4631C13.9308 31.6178 14.859 31.7209 15.7871 31.7209C23.2637 31.7209 29.9668 26.3584 31.359 18.6756C32.9574 10.0647 27.234 1.81466 18.6746 0.26779ZM29.6574 18.3662C29.5543 18.8819 29.4512 19.449 29.2965 19.9131L16.7668 15.2209V1.81466C17.2824 1.86623 17.8496 1.91779 18.3652 2.02091C25.9449 3.4131 30.998 10.735 29.6574 18.3662ZM14.9105 1.81466V14.9115H1.86522C1.91678 14.3959 1.96834 13.8287 2.07147 13.3131C3.20584 6.86779 8.67147 2.22716 14.9105 1.81466ZM13.3121 29.6584C9.65115 28.9881 6.45428 26.9256 4.28865 23.8318C2.79334 21.7178 1.96834 19.2428 1.81365 16.7678H15.6324L28.5746 21.6147C26.064 27.3381 19.7574 30.8443 13.3121 29.6584Z"
                        fill="white"/>
                        </svg>
                     </div>
                     <h4 class="text-center font-semibold text-3xl green mb-3 mt-auto">
                        Affordable Branding Solution
                     </h4>
                     <p class="text-center text-white">
                        Get designs at a fraction of the cost, so you can focus your budget on growing your business.
                     </p>
                  </div>
               </div>
            </div>
        </div>
      </section>

      <!--Video Tutorial-->
      <section class="w-full">
         <div class="w-11/12 lg:w-4/5 mx-auto my-16 pb-16 text-center xl:text-left">
            <div class="flex flex-wrap mx-4">
               <div class="w-full px-4">
                  <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
                     <h2 class="heading">
                     How It Works?
                     </h2>
                     <span class="font-semibold text-lg text-white mb-2 block">
                     Watch this short tutorial and see how it works!
                     </span>                 
                  </div>
               </div>
               <Video videoUrl="https://www.youtube.com/watch?v=jt8MWdCHcWk&ab" />
            </div>
         </div>
      </section>
  
      <!--FOOTER-->
      <Footer />
   </div>
</template>

<script>
  export default {
  };
</script>
<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->
<style lang=""></style>
  