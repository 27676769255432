<template lang="en">
  <div class="font-inter animate-glassmorphismGlow backdrop-blur-md">
    <!-- BETA INFO -->
    <!-- <div class="w-full sm:text-base sticky top-0 bg-red-500 flex justify-center items-center text-white font-bold z-10">
      Important: As we are in the Beta Phase we recommend using Google Chrome, Mozilla Firefox, Opera, Microsoft Edge.
    </div> -->

    <Menu />

    <!-- HERO SECTION -->
    <div class="relative w-full h-screen overflow-hidden">
      <video preload="auto" loading="lazy" autoplay muted loop poster="@/assets/LY - Header.webp" class="absolute top-1/2 left-1/2 w-full h-full object-cover transform -translate-x-1/2 -translate-y-1/2">
        <source src="@/assets/LY - Header.mp4" type="video/mp4">
      </video>

      <div class="relative z-10 flex items-center justify-center h-full p-8">
        <div class="text-primary h-[60vh] relative flex items-center justify-center p-8">
          <div class="max-w-screen mx-auto animate-ease-in-out">
            <!-- Single Column -->
            <div class="max-w-lg mx-auto text-center">
              <h1 class="text-5xl xl:text-7xl font-bold tracking-tight text-white pt-4 sm:pt-0 animate-slideInUp">Your Business, Built Smarter.</h1>
              <p class="mt-4 text-base sm:text-lg leading-6 text-white font-bold">From Launch to Growth: Logo<span class="text-secondary">Ya</span> Creates Your Branding Assets and Fuels Growth with AI Marketing Tools.</p>
              <div class="mt-8">
                <button onclick="ml('show', 'PaZtC4', true)"
                href="javascript:void(0)" class="animate-jump-in animate-delay-500 ml-onclick-form btn-green text-primary font-bold font-inter border-none rounded-md p-3 sm:p-4">Get an early access</button>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div class="absolute inset-0 bg-[#0D142C] opacity-60"></div>
    </div>
    <!-- Testing Gradient Colors-->
    <div class="h-14 bg-gradient-to-b from-darkPrimary to-primary"></div>
    <!-- BENEFITS SECTION -->
    <section class="bg-cover animate-fade-up animate-zoom-in animate-delay-700 flex justify-center pt-20 pb-24 lg:pb-[250px]">
      <!-- Blobs -->
      <img src="../assets/blob.gif" alt="blob" class="absolute top-0 left-5 w-28 h-28 xl:w-40 xl:h-40"/>
      <img src="../assets/blob.gif" alt="blob" class="absolute bottom-5 left-1/2 xl:top-3/4 w-24 h-24 xl:w-32 xl:h-32"/>
      <img src="../assets/blob.gif" alt="blob" class="absolute top-1/2 right-0 xl:right-20 w-20 h-20 xl:w-24 xl:h-24"/>
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
               Our Benefits
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
                Save time, money and headaches with our platform.
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/time.svg" alt="Time"/>
              </div>
              <h3 class="title-card mb-0">
              Save time
              </h3>
              <p class="text-center text-white flex-grow">
                Launch faster with on-demand branding and marketing tools. With LogoYa, you can create a professional brand and marketing assets in minutes, giving you more time to focus on growing your business.              </p>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/money.svg" alt="Money bag" />
              </div>
              <h3 class="title-card mb-0">
              Save money
              </h3>
              <p class="text-center text-white flex-grow">
                Get branding and marketing support at a fraction of the cost. LogoYa offers a complete suite of AI-powered tools, empowering you to establish and grow your business affordably.              </p>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:w-1/3 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/pencil.svg" alt="Pencil" />
              </div>
              <h3 class="title-card mb-0">
              DIY
              </h3>
              <p class="text-center text-white flex-grow">
                From logos to business strategies, LogoYa equips you with everything you need to grow. No experience? No problem! LogoYa’s tools are built for everyone, helping you create, plan, and scale confidently.              </p>
            </div>
          </div>
        </div>
      </div>
    </section>    
    <!--HOW IT WORKS SECTION-->
    <section class="animate-fade-up animate-delay-700 flex justify-center pt-20 lg:pt-[80px] pb-12 lg:pb-[90px]">
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
               Our Process
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
                4 Steps to your great business
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/vision-white.svg" alt="preview eye" />
              </div>
              <h3 class="title-card">
                Enter Your Business Details
              </h3>
              <p class="text-center text-white flex-grow">
                Share your business details and LogoYa’s AI will customize branding and marketing solutions for you.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/choose-style.svg" alt="choose style" />
              </div>
              <h3 class="title-card">
                Branding & Marketing Toolkit
              </h3>
              <p class="text-center text-white flex-grow">
                Access a suite of tools in one hub to generate logos, brand assets, and tailored marketing strategies.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/branding.svg" alt="branding unleashed" />
              </div>
              <h3 class="title-card">
                Unlock Full Potential
              </h3>
              <p class="text-center text-white flex-grow">
                Start with the basics, and when you’re ready, upgrade for premium tools and advanced customization.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-36" src="../assets/landingImages/keep-growing.svg" alt="keep growing" />
              </div>
              <h3 class="title-card">
                Build, Grow and Succeed
              </h3>
              <p class="text-center text-white flex-grow">
                Download and apply your assets as your business grows. LogoYa supports your journey to success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TOOLS SECTION -->
    <section class="bg-homepagebg-tools bg-contain animate-fade-up animate-delay-700 flex justify-center pt-20 lg:pt-[80px]    pb-12 lg:pb-[90px]">
      <div class="container">
        <div class="flex flex-wrap mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <h2 class="heading">
                Our Tools 
              </h2>
              <span class="font-semibold text-lg text-white mb-2 block">
               Build and grow your business
              </span> 
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mx-4 gap-y-12">
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/businessNameGenerator.png" alt="icon" />
              </div>
              <h3 class="title-card">
              Business Name Generator
              </h3>
              <p class="text-center text-white grow h-[20%]">
              Generate unique and catchy business names with just a click.
              </p>
              <div class="text-center">
                <router-link to="/businessnamegenerator">
                  <button class="bg-white text-gray-400 font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2 flex flex-grow items-center justify-center"> Available soon</button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/marketingStrategyGenerator.png" alt="icon" />
              </div>
              <h3 class="title-card">
              Marketing Strategy Generator
              </h3>
              <p class="text-center text-white grow h-[20%]">
              Understand your perfect customer tailored to your brand.
              </p>
              <div class="text-center">
                <router-link to="/marketingstrategygenerator">
                  <button class="bg-white text-gray-400 font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2"> Available soon
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/businessPlanGenerator.png" alt="icon" />
              </div>
              <h3 class="title-card">
              Business Plan Generator
              </h3>
              <p class="text-center text-white grow h-[20%]">
              Condense your business into a compelling one-page summary.
              </p>
              <div class="text-center">
                <router-link to="/">
                  <button :disabled="true" class="bg-white text-gray-400 disabled font-bold font-inter border-none rounded-md p-4 px-6 mt-4 xl:mt-2"> Available soon
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:w-1/4 px-4">
            <div class="glass p-[20px] md:px-2 xl:px-8 rounded-[20px] shadow-md mb-8 h-full flex flex-col items-center">
              <div class="w-fit grow">
                <img class="object-contain h-24 w-24" src="@/assets/tools/brandBuilder.png" alt="icon" />
              </div>
              <h3 class="title-card">
              Brand Builder
              </h3>
              <p class="text-center text-white grow h-[20%]">
              Build branding that resonate with your business in just few steps.
              </p>
              <div class="text-center">
                <router-link to="/brandbuilder">
                  <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5 mt-4 xl:mt-2"
                  > Try Now
                  </button>  
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/all-tools">
          <button class="btn-green text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-12">
          Check out all tools →
          </button>  
        </router-link>
      </div>
    </section>
    <!--Waitlist-->
    <section class="w-full">
      <div class="w-11/12 lg:w-3/4 mx-auto my-8 pb-14 xl:pb-40 pt-16 text-center xl:text-left">
        <div class="flex flex-col items-center justify-center rounded-[20px] mx-auto py-14 px-8 lg:px-16 bg-gradient-to-r from-green-400 to-blue-500">
          <div class="w-full lg:w-2/3 text-white font-inter text-center">
            <h2 class="font-bold text-[32px] md:text-[40px] mb-6">
            Join our waitlist today and be among the first to experience the
            magic of Logo<span class="text-secondary">Ya</span>.
            </h2>
          </div>
          <button
          onclick="ml('show', 'PaZtC4', true)"
          href="javascript:void(0)"
          class="ml-onclick-form bg-secondary text-primary font-bold font-inter border-none rounded-md p-4 px-6 mt-5">
          Get an early access
          </button>
        </div>
      </div>
    </section>
    <!-- FAQ Section -->
    <section class="max-w-screen-xl mx-auto min-h-sceen">
      <div class="container flex flex-col mx-auto my-8 p-4 md:p-8">
        <h2 class="heading">Frequently Asked Questions</h2>
        <div class="divide-y xl:m-16 lg:px-12 xl:px-32 divide-gray-700">
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span> What is Logo<span class="text-secondary">Ya</span>?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <div class="transition-all duration-500 ease-in-out overflow-hidden max-h-0 group-open:max-h-40 opacity-0 group-open:opacity-100">
              <p class="p-4 group-open:animate-slideInDown">Logo<span class="text-secondary">Ya</span> is an AI-powered platform that helps small businesses and startups establish a brand identity and accelerate growth. With tools for branding, marketing, and business planning, LogoYa is a one-stop solution for building and growing your business.</p>
            </div>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span> Do I need any experience?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
            <div class="transition-all duration-500 ease-in-out overflow-hidden max-h-0 group-open:max-h-40 opacity-0 group-open:opacity-100">
              <p class="p-4 group-open:animate-slideInDown">
                Not at all! Logo<span class="text-secondary">Ya</span> is built for beginners and experts alike. Our tools are intuitive and guide you step-by-step, so you’ll have a powerful brand and growth strategy ready to launch in no time.
				    </p>
          </div>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>Is Logo<span class="text-secondary">Ya</span> free to use?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
            <div class="transition-all duration-500 ease-in-out overflow-hidden max-h-0 group-open:max-h-40 opacity-0 group-open:opacity-100">
              <p class="p-4 group-open:animate-slideInDown">Some features are 100% free - especially for beginners who would like to check out Logo<span class="text-secondary">Ya</span>. But if you are serious about building and growing your business we have special plan for you!</p>
            </div>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>What do I receive in branding package?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <div class="transition-all duration-500 ease-in-out overflow-hidden max-h-0 group-open:max-h-40 opacity-0 group-open:opacity-100">
              <p class="p-4 group-open:animate-slideInDown">For now you will get: logo, social media ready assets (facebook, twitter, linkedin, youtube), jpg, png, svg.</p>
            </div>
			    </details>
          <details class="group glass m-2 p-4 text-white">
				    <summary class="flex justify-between items-center text-md lg:text-xl cursor-pointer list-none">
					    <span>How Logo<span class="text-secondary">Ya</span> can help me?</span>
					    <span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
				    </summary>
				    <div class="transition-all duration-500 ease-in-out overflow-hidden max-h-0 group-open:max-h-40 opacity-0 group-open:opacity-100">
              <p class="p-4 group-open:animate-slideInDown">Logo<span class="text-secondary">Ya</span> offers you the whole proccess to build the brand you want - from concept through branding to marketing.</p>
            </div>
			    </details>
        </div>
      </div>
    </section>

    <!--FOOTER-->
    <Footer />

  </div>
</template>
<script>
  export default {
    name: "LogoYa Homepage",
    methods: {
      allTools() {
        this.$router.push("/all-tools");
      },
    },
  };
</script>
<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->
<style lang="">
</style>