<template>
  <div class="bg-homepagebg-tools min-h-screen flex items-center justify-center p-4 bg-primary">
    <div class="max-w-5xl w-full grid grid-cols-1 md:grid-cols-2 gap-8 bg-white shadow-lg rounded-2xl overflow-hidden">
      <!-- Left Column -->
      <div class="flex flex-col text-left p-8">
        <h1 class="text-4xl font-bold text-gray-900 mb-4">🚀 Build & grow your brand in minutes</h1>
        <p class="text-gray-600 mb-6">LogoYa is a one-stop platform for creating logos, business names, marketing strategies, and more. Join our early access and be among the first to shape the future of branding!</p>
        <ul class="space-y-2">
          <li class="flex items-start">
            <span class="text-gray-700">✅ All-in-One Branding — From logos to marketing strategies, everything in just a few clicks.</span>
          </li>
          <li class="flex items-start">
            <span class="text-gray-700">✅ Save Time & Money — No need for expensive agencies. Create your brand identity in minutes.</span>
          </li>
          <li class="flex items-start">
            <span class="text-gray-700">✅ Exclusive Resources — Access helpful guides and tools to make branding easier.</span>
          </li>
        </ul>
        <div class="ml-embedded" data-form="eqtfzK"></div>
      </div>
      <!-- Right Column -->
      <div class="flex items-center justify-center p-8">
        <img src="../assets/earlyAccessImage.png" alt="earlyAccessImage" class="max-w-full h-auto rounded-lg shadow-md">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage'
}
</script>

<script>
//mailer lite
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>