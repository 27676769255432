const axios = require('axios');

// Funkcja do generowania nazw biznesowych
const generateBusinessNames = async (keyword, industry) => {
  const apiKey = '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';

  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      {
        role: 'system',
        content:
          'You are a marketing specialist. Generate 10 creative business name ideas based on the given keyword and industry.',
      },
      {
        role: 'user',
        content: `Keyword: ${keyword}, Industry: ${industry}`,
      },
    ],
  };

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const generatedText = response.data.choices[0].message.content;
    return generatedText.split('\n').filter((name) => name.trim() !== ''); // Podział na listę
  } catch (error) {
    console.error('API error:', error);
    throw new Error('Failed to fetch business names');
  }
};

module.exports = generateBusinessNames;
