<template>
  <div class="generator-container">
    <h1 class="text-2xl font-bold mb-4">Business Name Generator</h1>
    <form @submit.prevent="generateNames">
      <div class="mb-4">
        <label for="keyword" class="block text-sm font-medium">Keyword:</label>
        <input
          id="keyword"
          v-model="keyword"
          type="text"
          placeholder="Enter a keyword (e.g., your name)"
          class="input-field"
          required
        />
      </div>
      <div class="mb-4">
        <label for="industry" class="block text-sm font-medium">Industry:</label>
        <select
          id="industry"
          v-model="industry"
          class="select-field"
          required
        >
          <option value="" disabled>Select an industry</option>
          <option value="restaurant">Restaurant</option>
          <option value="technology">Technology</option>
          <option value="fashion">Fashion</option>
          <!-- Add more industries as needed -->
        </select>
      </div>
      <button type="submit" class="btn-primary">Generate Names</button>
    </form>
  </div>
</template>

<script>
import generateBusinessNames from '../../api/businessNameGenerator';

export default {
  data() {
    return {
      keyword: '',
      industry: '',
    };
  },
  methods: {
    async generateNames() {
      try {
        // Call the API to generate names
        const names = await generateBusinessNames(this.keyword, this.industry);

        // Pass the results to the next route
        this.$router.push({
          path: '/businessnameresults',
          query: { names: JSON.stringify(names) },
        });
      } catch (error) {
        console.error('Error generating business names:', error.message);
        alert('Failed to generate business names. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
.generator-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}
.input-field,
.select-field {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}
.btn-primary {
  background-color: #1d4ed8;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #2563eb;
}
</style>
