<template>
  <!-- LOGO BAR -->
  <div :class="['absolute top-0 left-0 w-full h-[80px] flex justify-between items-center text-white font-bold p-4 lg:p-8 z-20', isMenuOpen ? 'bg-primary' : 'bg-transparent']">
    <router-link to="/">
      <img class="w-[6em] xl:w-[10em]" src="../assets/LogoYa-logo.svg" alt="logo" />
    </router-link>
  
    <!-- Navigation Menu -->
    <ul class="hidden md:flex text-2xl space-x-8 font-bold">
      <li class="hover:scale-110 transition-transform duration-200">
        <router-link to="/all-tools" class="hover:text-secondary">Our tools</router-link>
      </li>
      <li class="hover:scale-110 transition-transform duration-200">
        <a href="#" class="hover:text-secondary">LogYa Blog</a>
      </li>
      <li>
        <a onclick="ml('show', 'PaZtC4', true)"
           href="javascript:void(0)"
           class="animate-jump-in animate-delay-500 ml-onclick-form btn-green text-primary font-bold font-inter border-none rounded-md p-3 sm:p-4">
            Try BETA
        </a>
      </li>
    </ul>

    <!-- Mobile Menu Button -->
    <div class="md:hidden">
      <button @click="toggleMenu" class="focus:outline-none">
        <svg v-if="!isMenuOpen" class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
        <svg v-else class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>

    <!-- Mobile Menu -->
    <transition name="slide-down">
      <ul v-if="isMenuOpen" class="md:hidden absolute right-0 top-full space-y-2 bg-primary pb-4 text-white w-full">
        <li class="flex justify-center hover:scale-110 transition-transform duration-200">
          <router-link to="/all-tools" class="block px-4 py-2 hover:text-secondary w-1/2">Our tools</router-link>
        </li>
        <li class="flex justify-center hover:scale-110 transition-transform duration-200">
          <a href="#" class="block px-4 py-2 hover:text-secondary w-1/2">LogYa Blog</a>
        </li>
        <li class="flex justify-center">
          <a onclick="ml('show', 'PaZtC4', true)" href="javascript:void(0)"
            class="block btn-green text-primary px-4 py-2 rounded-md hover:bg-green-600 w-1/2">
            Try BETA
          </a>
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};
</script>

<style scoped>
.slide-down-enter-active, .slide-down-leave-active {
  transition: max-height 1s ease, opacity 1s ease;
  overflow: hidden;
}
.slide-down-enter, .slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-down-enter-to, .slide-down-leave {
  max-height: 500px;
  opacity: 1;
}
</style>
