<template>
  <div class="relative w-full max-w-6xl mx-auto md:px-6">
    <div v-if="!isPlaying" @click="playVideo" class="max-w-5xl mx-auto h-full flex items-center rounded-md">
      <img
        :src="thumbnailUrl"
        alt="Video Thumbnail"
        class="w-full h-full shadow-2xl aspect-video object-cover overflow-hidden rounded-md" />
      <button
        class="absolute inset-0 flex justify-center items-center" aria-label="Play">
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 32 32">
          <path fill="#102542" d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1 1 0 0 1 11 23"/>
          <path fill="#7CDB4F" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788"/>
        </svg>
      </button>
    </div>
  
    <div v-if="isPlaying" class="max-w-5xl mx-auto h-full flex items-center rounded-md">
      <iframe
        :src="youtubeUrl"
        class="w-full h-full shadow-2xl aspect-video overflow-hidden rounded-md"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: "Video",
    props: {
      videoUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isPlaying: false,
      };
    },
    computed: {
      videoId() {
        try {
          const urlParams = new URLSearchParams(new URL(this.videoUrl).search);
          return urlParams.get("v");
        } catch (error) {
          console.warn("URL Error:", error);
          return null;
        }
      },
      youtubeUrl() {
        return this.videoId
          ? `https://www.youtube.com/embed/${this.videoId}?autoplay=1&rel=0`
          : "";
      },
      thumbnailUrl() {
        return this.videoId
          ? `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`
          : "";
      },
    },
    methods: {
      playVideo() {
        this.isPlaying = true;
      },
    },
  };
</script>
 
<style scoped>
</style>
  