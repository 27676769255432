<template>
  <div class="results-container">
    <h1 class="text-2xl font-bold mb-4">Generated Business Names</h1>
    <ul v-if="businessNames.length > 0" class="results-list">
      <li
        v-for="(name, index) in businessNames"
        :key="index"
        class="result-item"
      >
        {{ name }}
      </li>
    </ul>
    <p v-else class="text-gray-500">No results available. Please try again.</p>
    <button @click="goBack" class="btn-secondary mt-4">Go Back</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      businessNames: [],
    };
  },
  created() {
    // Parse names from the query params
    const names = this.$route.query.names
      ? JSON.parse(this.$route.query.names)
      : [];
    this.businessNames = names;
  },
  methods: {
    goBack() {
      this.$router.push('/businessnamegenerator');
    },
  },
};
</script>

<style scoped>
.results-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}
.results-list {
  list-style: none;
  padding: 0;
}
.result-item {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}
.btn-secondary {
  background-color: #6b7280;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.btn-secondary:hover {
  background-color: #4b5563;
}
</style>
