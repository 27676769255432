<template>
  <div class="bg-homepagebg-tools min-h-screen flex items-center justify-center p-4">
    <div class="max-w-5xl w-full grid grid-cols-1 md:grid-cols-2 gap-8 bg-white shadow-lg rounded-2xl overflow-hidden">
      <!-- Left Column -->
      <div class="flex flex-col text-left p-8">
        <h1 class="text-4xl font-bold text-gray-900 mb-4">🚀 Start your business with confidence – Download the free workbook</h1>
        <p class="text-gray-600 mb-6">Starting a business can feel overwhelming — but it doesn’t have to be. Download this step-by-step workbook to help you lay a solid foundation for your business idea.</p>
        <ul class="space-y-2">
          <li class="flex items-start">
            <span class="text-gray-700">✅ Discover how to find your idea, build your brand, and get your first customers.</span>
          </li>
          <li class="flex items-start">
            <span class="text-gray-700">✅ Get a clear action plan and avoid common mistakes.</span>
          </li>
          <li class="flex items-start">
            <span class="text-gray-700">✅ All in a simple, practical workbook — for free!</span>
          </li>
        </ul>
        <div class="ml-embedded" data-form="iFcLvL"></div>
      </div>
      <!-- Right Column -->
      <div class="flex items-center justify-center p-8">
        <img src="../assets/lmImage.png" alt="earlyAccessImage" class="max-w-full h-auto rounded-lg shadow-md">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage'
}
</script>

<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->